
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

type Payroll = {
  id: number;
  period: string;
  date_from: string;
  duration_h_m: string;
  tasks: Record<string, any>[];
};

@Component({
  filters: {
    date_from(value: string) {
      if (!value) return;

      return moment(value).format("Do MMMM");
    }
  }
})
export default class PayrollPage extends Vue {
  @Prop(Number) readonly payrollId!: number;

  payroll: null | Payroll = null;

  mounted() {
    this.fetchPayrollById(this.payrollId);
  }

  fetchPayrollById(id: number) {
    this.$store
      .dispatch("payrolls/fetchById", id)
      .then(({ data }) => (this.payroll = data.data));
  }
}
